import StaticWadoClient from '../qido/StaticWadoClient';
import dcmjs from 'dcmjs';
import DICOMWeb from '../../../DICOMWeb/';
import RetrieveMetadataLoader from './retrieveMetadataLoader';
import { sortStudySeries, sortingCriteria } from '../../sortStudy';
import getSeriesInfo from '../../getSeriesInfo';
import {
  createStudyFromSOPInstanceList,
  addInstancesToStudy,
} from './studyInstanceHelpers';

import X2JS from 'x2js';

import errorHandler from '../../../errorHandler';
import { getXHRRetryRequestHook } from '../../../utils/xhrRetryRequestHook';

import { redux } from '@ohif/core';
const { actions } = redux;

const { naturalizeDataset } = dcmjs.data.DicomMetaDictionary;

/**
 * Map series to an array of SeriesInstanceUID
 * @param {Arrays} series list of Series Instance UIDs
 * @returns {Arrays} A list of Series Instance UIDs
 */
function mapStudySeries(series) {
  return series.map(series => getSeriesInfo(series).SeriesInstanceUID);
}

function attachSeriesLoader(server, study, seriesLoader) {
  study.seriesLoader = Object.freeze({
    hasNext() {
      return seriesLoader.hasNext();
    },
    async next() {
      const series = await seriesLoader.next();
      await addInstancesToStudy(server, study, series.sopInstances);
      return study.seriesMap[series.seriesInstanceUID];
    },
  });
}

/**
 * Creates an immutable series loader object which loads each series sequentially using the iterator interface
 * @param {DICOMWebClient} dicomWebClient The DICOMWebClient instance to be used for series load
 * @param {string} studyInstanceUID The Study Instance UID from which series will be loaded
 * @param {Array} seriesInstanceUIDList A list of Series Instance UIDs
 * @returns {Object} Returns an object which supports loading of instances from each of given Series Instance UID
 */
function makeSeriesAsyncLoader(
  dicomWebClient,
  studyInstanceUID,
  seriesInstanceUIDList
) {
  return Object.freeze({
    hasNext() {
      return seriesInstanceUIDList.length > 0;
    },
    async next() {
      const seriesInstanceUID = seriesInstanceUIDList.shift();
      const sopInstances = await dicomWebClient.retrieveSeriesMetadata({
        studyInstanceUID,
        seriesInstanceUID,
      });
      return { studyInstanceUID, seriesInstanceUID, sopInstances };
    },
  });
}

/**
 * Class for async load of study metadata.
 * It inherits from RetrieveMetadataLoader
 *
 * It loads the one series and then append to seriesLoader the others to be consumed/loaded
 */
export default class RetrieveMetadataLoaderAsync extends RetrieveMetadataLoader {
  configLoad() {
    let { server } = this;

    this.beta = JSON.parse(sessionStorage.getItem('beta'))
    this.urlApi = sessionStorage.getItem('urlApi')
    this.urlWado = sessionStorage.getItem('urlWado')
    this.studyUID = sessionStorage.getItem('studyUID')
    this.token = sessionStorage.getItem('token')
    let storageComplementos = sessionStorage.getItem('complementos')

    if (storageComplementos !== undefined
      && storageComplementos !== null
      && storageComplementos !== ''
      && storageComplementos !== 'undefined'
      && storageComplementos !== 'null') {
      this.complementos = JSON.parse(sessionStorage.getItem('complementos'))
    }

    if (this.beta) {
      let newUrlRS = this.urlWado.endsWith('/') ? this.urlWado + 'rs' : this.urlWado + '/rs';
      let newUrlWado = this.urlWado.endsWith('/') ? this.urlWado + 'wado' : this.urlWado + '/wado';

      this.server.qidoRoot = newUrlRS;
      this.server.wadoRoot = newUrlRS;
      this.server.wadoUriRoot = newUrlWado;
    }

    let header = {
      ...DICOMWeb.getAuthorizationHeader(server)
    };

    const client = new StaticWadoClient({
      ...server,
      url: server.qidoRoot,
      headers: header,
      errorInterceptor: errorHandler.getHTTPErrorHandler(),
      requestHooks: [getXHRRetryRequestHook()],
    });

    this.client = client;
    this.clientApiJson = {}
    this.seriesJson = []
  }

  /**
   * @returns {Array} Array of preLoaders. To be consumed as queue
   */
  *getPreLoaders() {
    const preLoaders = [];
    const {
      studyInstanceUID,
      filters: { seriesInstanceUID } = {},
      client
    } = this;

    if (seriesInstanceUID) {
      const options = {
        studyInstanceUID,
        queryParams: { SeriesInstanceUID: seriesInstanceUID },
      };
      preLoaders.push(client.searchForSeries.bind(client, options));
    }
    // Fallback preloader
    preLoaders.push(client.searchForSeries.bind(client, { studyInstanceUID }));

    yield* preLoaders;
  }

  *getComplementPreLoader(complementStudyUID) {
    const preLoaders = [];
    const {
      client
    } = this;

    preLoaders.push(client.searchForSeries.bind(client, { studyInstanceUID: complementStudyUID }));
    yield* preLoaders;
  }

  async preLoad() {
    const preLoaders = this.getPreLoaders();

    // seriesData is the result of the QIDO-RS Search For Series request
    // It's an array of Objects containing DICOM Tag values at the Series level
    const seriesData = await this.runLoaders(preLoaders);

    const seriesSorted = sortStudySeries(
      seriesData,
      sortingCriteria.seriesSortCriteria.seriesInfoSortingCriteria
    );
    const seriesInstanceUIDsMap = mapStudySeries(seriesSorted);

    return {
      seriesInstanceUIDsMap,
      seriesData
    };
  }

  async load(preLoadData) {
    const { client, studyInstanceUID } = this;

    const seriesAsyncLoader = makeSeriesAsyncLoader(
      client,
      studyInstanceUID,
      preLoadData.seriesInstanceUIDsMap
    );

    const firstSeries = await seriesAsyncLoader.next();

    return {
      sopInstances: firstSeries.sopInstances,
      asyncLoader: seriesAsyncLoader,
      seriesData: preLoadData.seriesData
    };
  }

  async posLoad(loadData) {
    const { server } = this;

    const { sopInstances, asyncLoader, seriesData } = loadData;

    const study = await createStudyFromSOPInstanceList(server, sopInstances);

    // TODO: Should this be in a helper
    const seriesDataNaturalized = seriesData.map(naturalizeDataset);

    let modality = '';

    seriesDataNaturalized.forEach((series, idx) => {
      const seriesDataFromQIDO = {
        SeriesInstanceUID: series.SeriesInstanceUID,
        SeriesDescription: series.SeriesDescription,
        SeriesNumber: series.SeriesNumber,
        Modality: series.Modality,
        instances: [],
      };

      if (study.series[idx]) {
        study.series[idx] = Object.assign(
          seriesDataFromQIDO,
          study.series[idx]
        );
      } else {
        study.series[idx] = seriesDataFromQIDO;
      }

      study.seriesMap[series.SeriesInstanceUID] = study.series[idx];
      modality = series.Modality;
    });

    this.setWindowLevelModality(modality)

    if (asyncLoader.hasNext()) {
      attachSeriesLoader(server, study, asyncLoader);
    }

    return study;
  }

  async getComplementsData() {
    const { client, server } = this;
    let complementSeries = [];
    let seriesMap = {};

    for (let complementUID of this.complementos) {
      let study = {};
      const preLoaders = this.getComplementPreLoader(complementUID);
      let seriesData = await this.runLoaders(preLoaders);

      const seriesSorted = sortStudySeries(
        seriesData,
        sortingCriteria.seriesSortCriteria.seriesInfoSortingCriteria
      );

      const seriesInstanceUIDsMap = mapStudySeries(seriesSorted);

      const seriesLoader = makeSeriesAsyncLoader(
        client,
        complementUID,
        seriesInstanceUIDsMap
      );

      const firstSeries = await seriesLoader.next();

      study = await createStudyFromSOPInstanceList(server, firstSeries.sopInstances);

      const seriesDataNaturalized = seriesData.map(naturalizeDataset);

      seriesDataNaturalized.forEach((series, idx) => {
        const seriesDataFromQIDO = {
          SeriesInstanceUID: series.SeriesInstanceUID,
          SeriesDescription: series.SeriesDescription,
          SeriesNumber: series.SeriesNumber,
          Modality: series.Modality,
          instances: [],
        };

        if (study.series[idx]) {
          study.series[idx] = Object.assign(
            seriesDataFromQIDO,
            study.series[idx]
          );
        } else {
          study.series[idx] = seriesDataFromQIDO;
        }

        complementSeries.push(study.series[idx])

        study.seriesMap[series.SeriesInstanceUID] = study.series[idx];
        seriesMap[series.SeriesInstanceUID] = study.series[idx];
      });

      if (seriesLoader.hasNext()) {
        do {
          const series = await seriesLoader.next();
          await addInstancesToStudy(server, study, series.sopInstances);
        } while (seriesLoader.hasNext());
      }
    }

    return { complementSeries, seriesMap };
  }

  async getClientApiJson(studyUID) {
    const url = this.urlApi + 'client-api/patients?studyUID=' + studyUID

    let headers = {
      'Accept': 'application/dicom+json'
    }

    if (this.token && this.token !== 'undefined') {
      headers = {
        ...headers,
        'Unidade-token': this.token
      };
    }

    let result = {}
    await fetch(url, {
      method: 'GET',
      headers: headers
    })
      .then((response) => response.json())
      .then((data) => {
        result = data;
      })
      .catch((error) => {
        console.error('Error:', error);
      });

    return result;
  }

  async xmlRequest() {
    const series = this.clientApiJson.studies[0].series;
    let seriesJsons = [];
    let seriesPromises = [];
    let x2js = new X2JS();

    for (const serie of series) {
      const series_iuid = serie.series_iuid;
      const url = `${this.urlApi}client-api/patients/series-xml?studyUID=${this.studyUID}&seriesUID=${series_iuid}`

      let serieXmls = [];

      seriesPromises.push(
        fetch(url, { method: 'GET' })
          .then(response => response.text())
          .then((results) => {
            serieXmls = JSON.parse(results);
            return serieXmls;
          })
          .catch(error => {
            console.log('error', error);
          }));
    }

    const seriesXmlRequests = await Promise.all(seriesPromises)

    for (const xmlArray of seriesXmlRequests) {
      let jsons = [];
      for (const xml of xmlArray) {
        let document = x2js.xml2js(xml);
        let attrs = document.dataset.attr;
        jsons.push(attrs);
      }
      seriesJsons.push(jsons);
    }

    return seriesJsons;
  }

  convertSeries() {
    let series = []
    const study = this.clientApiJson.studies[0];
    const studySeries = this.clientApiJson.studies[0].series;

    for (const serie of studySeries) {
      let seriesNumber = parseInt(serie.series_no)
      let serieConvertida = {
        "0008103E": {
          Value: [serie.series_desc],
          vr: "LO"
        },
        "0020000D": {
          Value: [study.study_iuid],
          vr: "UI"
        },
        "0020000E": {
          Value: [serie.series_iuid],
          vr: "UI"
        },
        "00080060": {
          Value: [serie.modality],
          vr: "CS"
        },
        "00200011": {
          Value: [seriesNumber],
          vr: "IS"
        },
        "00080005": {
          Value: ['ISO_IR 100'],
          vr: "CS"
        },
        "00080054": {
          Value: ['mobilemed'],
          vr: "AE"
        },
        "00080056": {
          Value: ['ONLINE'],
          vr: "CS"
        },
        "00080060": {
          Value: [serie.modality],
          vr: "CS"
        },
        "00080201": {
          Value: [''],
          vr: "SH"
        },
        "00081190": {
          Value: [`${this.urlApi}client-api/patients?studyUID=${study.study_iuid}&seriesUID=${serie.series_iuid}`],
          vr: "UR"
        },
        "00200011": {
          Value: [serie.series_no],
          vr: "IS"
        },
        "00201209": {
          Value: [serie.instances.length],
          vr: "IS"
        },
        "00400244": {
          Value: [''],
          vr: "DA"
        },
        "00400245": {
          Value: [''],
          vr: "TM"
        },
        "00400275": {
          Value: [''],
          vr: "SQ"
        },
        "Symbol(INFO)": {
          Modality: serie.modality,
          SeriesInstanceUID: serie.series_iuid,
          SeriesNumber: serie.series_no,
          isLowPriority: false
        }
      }

      series.push(serieConvertida)
    }

    return series;
  }

  convertInstances() {
    let instancesMetadata = [];

    for (const series of this.seriesJson) {
      let instances = [];
      for (const serie of series) {
        let metadata = {};
        for (const tag of serie) {
          let tagName = tag._tag;
          let value = tag.__text
          let vr = tag._vr
          let obj = {}

          if (vr === "US") {
            value = [Number(value)]
          } else if (value && value.includes("\\")) {
            value = value.split("\\")
          } else if (!value) {
            value = []
          } else {
            value = [value]
          }

          obj[tagName] = {
            Value: value,
            vr: vr
          }
          metadata = { ...metadata, ...obj }
        }
        instances.push(metadata);
      }
      instancesMetadata.push(instances);
    }

    return instancesMetadata;
  }

  async getMetadata() {
    const { server } = this;
    let instancesMetadata = []
    let firstSeriesMetaData = []

    await this.getClientApiJson(this.studyUID)
      .then((resp) => {
        this.clientApiJson = resp
      })

    if (this.complementos.length > 0) {
      for (let complemento of this.complementos) {
        await this.getClientApiJson(complemento)
          .then((resp) => {
            this.clientApiJson.studies[0].series = this.clientApiJson.studies[0].series.concat(resp.studies[0].series)
          })
      }
    }

    await this.xmlRequest()
      .then((result) => {
        this.seriesJson = result
        instancesMetadata = this.convertInstances()
        firstSeriesMetaData = instancesMetadata[0]
      })

    const study = await createStudyFromSOPInstanceList(server, firstSeriesMetaData);

    let seriesMetaData = this.convertSeries()
    const seriesDataNaturalized = seriesMetaData.map(naturalizeDataset);
    let modality = '';

    seriesDataNaturalized.forEach((series, idx) => {
      const seriesDataFromQIDO = {
        SeriesInstanceUID: series.SeriesInstanceUID,
        SeriesDescription: series.SeriesDescription,
        SeriesNumber: series.SeriesNumber,
        Modality: series.Modality,
        instances: [],
      };

      if (study.series[idx]) {
        study.series[idx] = Object.assign(
          seriesDataFromQIDO,
          study.series[idx]
        );
      } else {
        study.series[idx] = seriesDataFromQIDO;
      }

      study.seriesMap[series.SeriesInstanceUID] = study.series[idx];
      modality = series.Modality;
    });

    this.setWindowLevelModality(modality)

    for (const [index, inst] of instancesMetadata.entries()) {
      if (index > 0) {
        await addInstancesToStudy(server, study, inst);
      }
    }

    return study
  }

  setWindowLevelModality(modality) {
    let windowLevelData = {}

    switch (modality) {
      case 'CT':
        windowLevelData = {
          1: { description: 'Abdomen', window: '400', level: '60' },
          2: { description: 'Angio', window: '600', level: '300' },
          3: { description: 'Bone', window: '1500', level: '300' },
          4: { description: 'Brain', window: '80', level: '40' },
          5: { description: 'Chest', window: '400', level: '40' },
          6: { description: 'Lungs', window: '1500', level: '-400' },
        }
        break
      case 'MG':
        windowLevelData = {
          1: { description: '[30/60]', window: '60', level: '30' },
          2: { description: '[125/250]', window: '250', level: '125' },
          3: { description: '[500/1000]', window: '1000', level: '500' },
          4: { description: '[1875/3750]', window: '3750', level: '1875' },
          5: { description: '[3750/7500]', window: '7500', level: '3750' },
          6: { description: '[7500/15000]', window: '15000', level: '7500' },
          7: { description: '[15000/30000]', window: '30000', level: '15000' },
          8: { description: '[30000/60000]', window: '60000', level: '30000' }
        }
        break
      default:
        windowLevelData = {
          1: { description: '[20/40]', window: '40', level: '20' },
          2: { description: '[40/80]', window: '80', level: '40' },
          3: { description: '[80/160]', window: '160', level: '80' },
          4: { description: '[160/320]', window: '320', level: '160' },
          5: { description: '[320/640]', window: '640', level: '320' },
          6: { description: '[640/1280]', window: '1280', level: '640' },
          7: { description: '[1280/2560]', window: '2560', level: '1280' },
          8: { description: '[2560/5120]', window: '5120', level: '2560' }
        }
        break
    }

    window.store.dispatch(actions.setUserPreferences({ windowLevelData: windowLevelData }));
  }
}
